<template>
  <AdminPage>
    <Notification
      v-if="error"
      type="danger"
    >
      {{ error }}
    </Notification>

    <Tabs
      :tabs="tabs"
      v-model="activeTab"
      size="small"
    />
    <RecurringClaimsReport
      v-if="activeTab === 'recurring_claims'"
    />
  </AdminPage>

</template>

<script>
import AdminPage from "@/components/AdminPage.vue";
import Notification from "@/components/Notification.vue";
import Tabs from "@/components/Tabs.vue";
import RecurringClaimsReport from "@/components/admin/RecurringClaimsReport.vue";

export default {
  components: {
    AdminPage,
    Notification,
    Tabs,
    RecurringClaimsReport
  },
  computed: {
    currentUser: function() {
      return this.$store.getters.currentUser;
    }
  },
  data() {
    return {
      error: null,
      // tabs
      tabs: [
        {
          name: 'recurring_claims',
          label: "Recurring Deal Claims"
        }
      ],
      activeTab: 'recurring_claims',
    }
  },
  mounted() {
    
  },
  methods: {
    
  }
}
</script>
