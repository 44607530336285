<template>
  <div>
    <nav class="level">
      <!-- Left side -->
      <div class="level-left">
        <div class="field has-addons">
          <b-datepicker
            placeholder="Date range..."
            v-model="dates"
            range
          ></b-datepicker>
          <div class="control">
            <input
              class="input"
              type="text"
              placeholder="Min count..."
              v-model="moreThan"
            />
          </div>
          <div class="control">
            <a
              class="button"
              @click="updateTransactions"
            >
              Update
            </a>
          </div>
        </div>
      </div>
      <!-- Right side -->
      <div class="level-right">
        
      </div>
    </nav>

    <AdminRecurringClaimsTable
      :data="transactions"
      @change="updateTransactions"
    />
  </div>
</template>

<script>
import AdminRecurringClaimsTable from "@/components/table/AdminRecurringClaims.vue"
import api from "@/service/api.js"

export default {
  components: {
    AdminRecurringClaimsTable
  },
  computed: {
    currentUser: function() {
      return this.$store.getters.currentUser
    },
    startDate: function() {
      return this.dates[0].toISOString()
    },
    endDate: function() {
      return this.dates[1].toISOString()
    }
  },
  data() {
    return {
      loading: false,
      submitting: false,
      dates: [],
      moreThan: 5,
      transactions: []
    }
  },
  mounted() {
    this.initDates()
    this.updateTransactions()
  },
  methods: {
    initDates() {
      const today = new Date()
      this.dates = [new Date(today.getFullYear(), today.getMonth(), today.getDate() - 30), today];
    },
    updateTransactions() {
      const params = {
        start_date: this.startDate,
        end_date: this.endDate,
        more_than: this.moreThan,
      }
      api.getAdminRepeatedClaims(this.currentUser.slug, params)
        .then(resp => {
          this.transactions = resp.data
        })
        .catch(error => {
          this.error = error
        })
    }
  }
}
</script>
