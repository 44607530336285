<template>
  <div class="table-container">
    <table class="table is-bordered is-striped is-narrow is-hoverable is-fullwidth">
      <thead>
        <tr>
          <th>User</th>
          <th>Organization</th>
          <th>Deal</th>
          <th colspan="3">Transactions</th>
        </tr>
      </thead>
      <tbody>
        <template
          v-for="r in data"
        >
          <tr
            v-for="(t, index) in r.transactions"
            :key="t.id"
          >
            <td
              v-if="index === 0"
              :rowspan="r.transactions.length"
            >
              {{ r.user.name }}
            </td>
            <td
              v-if="index === 0"
              :rowspan="r.transactions.length"
            >
              {{ r.deal.business.name }}
            </td>
            <td
              v-if="index === 0"
              :rowspan="r.transactions.length"
            >
              {{ r.deal.description }}<br />
              ({{ r.deal.slug }})
            </td>
            <td>
              {{ t.date }}
            </td>
            <td>
              {{ USD(t.amount).format() }}
            </td>
            <td>
              {{ t.description }}
            </td>
          </tr>
        </template>
      </tbody>
    </table>
  </div>
</template>

<script>
export default {
  components: {
    
  },
  props: {
    data: {
      type: Array,
      reqired: true
    }
  },
  data() {
    return {
      
    }
  },
  computed: {
    currentUser: function() {
      return this.$store.getters.currentUser
    }
  },
  methods: {
    
  }
}
</script>
